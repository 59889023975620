import React, {PropsWithChildren, useCallback, useMemo} from 'react';
import {Flex, Image} from "antd";
import {useLocaleContext} from "./translations/LocaleContext";
import {Link} from "react-router-dom";
import {Heading} from "./translations/Heading";
import {Label} from "./translations/Label";

export const CommonLayout: React.FC<PropsWithChildren> = (props) => {
    const { locale, options, setLocale } = useLocaleContext();
    const flag = useMemo(() => {
        return options.find(opt => opt.value !== locale)?.url;
    }, [locale, options]);
    const swapLocales = useCallback(() => {
        setLocale(options.find(opt => opt.value !== locale)?.value);
    }, [locale, options, setLocale]);

    return (
        <>
            <div className="space-align-block" style={{
                top: 0,
                width: '100%',
                height: '90px',
                zIndex: 9999998,
                position: 'fixed',
                backgroundColor: 'white'
            }}>
                <Flex justify={"center"}>
                    <span style={{width: '164px'}}>
                        <Flex justify={"center"}>
                            <Heading trl={'header.title'} level={3} />
                        </Flex>
                        <Flex justify={"center"}>
                            <Label trl={'header.subtitle'} italic />
                        </Flex>
                    </span>
                </Flex>
            </div>
            <div className="space-align-block" style={{
                top: 0,
                width: '100%',
                height: '90px',
                zIndex: 9999999,
                position: 'fixed',
            }}>
                <Flex justify={"space-between"} align="center" style={{width: '98%'}}>
                    <Link to={'/'}>
                        <Image src={'/logo.png'} height={'80px'} alt={'logo'} preview={false} style={
                            {
                                marginTop: '5px',
                                marginLeft: '5px'
                            }
                        }/>
                    </Link>
                    <span style={{width: '30px'}}>
                        <Flex justify={"center"}>
                            <Image src={flag} height={'20px'} alt={'switch language'} preview={false} onClick={swapLocales}/>
                        </Flex>
                    </span>
                </Flex>
            </div>

            <div style={{
                minHeight: 'calc(100vh - 90px)',
                backgroundColor: '#edede9'
            }}>
                <div style={{marginTop: '90px', overflowY: 'scroll', padding: '0.5em'}}>
                    {props.children}
                </div>
            </div>
        </>
    )
}